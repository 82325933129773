exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-produtor-js": () => import("./../../../src/pages/produtor.js" /* webpackChunkName: "component---src-pages-produtor-js" */),
  "component---src-pages-teatro-js": () => import("./../../../src/pages/teatro.js" /* webpackChunkName: "component---src-pages-teatro-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-aconteceu-js": () => import("./../../../src/templates/aconteceu.js" /* webpackChunkName: "component---src-templates-aconteceu-js" */),
  "component---src-templates-evento-js": () => import("./../../../src/templates/evento.js" /* webpackChunkName: "component---src-templates-evento-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-noticias-js": () => import("./../../../src/templates/noticias.js" /* webpackChunkName: "component---src-templates-noticias-js" */),
  "component---src-templates-programacao-js": () => import("./../../../src/templates/programacao.js" /* webpackChunkName: "component---src-templates-programacao-js" */)
}

